import React from "react"
import {
  Heading,
  Button,
  Box,
  Flex,
  Text,
  Spacer,
  Tag,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react"
import { Link } from "../../Link"
import { useSelector } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"

const ProjektzielHeader = ({
  projektName,
  backButton,
  title,
  schwerpunkt,
  summary,
  projektbeteiligteName,
  projektbeteiligte,
  leitbegriffeName,
  leitbegriffe,
  leitbegriffeBeschreibung,
  leitbegriffeExtendedBeschreibung,
  projektReferenz,
  forschungsbeteiligte,
  linkTextLeitbegriffe,
  linkUrlLeitbegriffe,
  summaryName,
}) => {
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  const query = useStaticQuery(graphql`
    query ForschungsHeaderQuery {
      de_DE: wpModeSettings(
        locale: { id: { eq: "de_DE" } }
        slug: { eq: "forschung" }
      ) {
        id
        acf_research_settings {
          forschungsbegriffe {
            institution
            disziplin
            name
          }
        }
      }
      en_US: wpModeSettings(
        locale: { id: { eq: "en_US" } }
        slug: { eq: "forschung" }
      ) {
        id
        acf_research_settings {
          forschungsbegriffe {
            institution
            disziplin
            name
          }
        }
      }
    }
  `)

  let settings = query[CURRENT_LOCALE]?.acf_research_settings
  const CURRENT_LANG = useSelector(state => state.currentLang)
  return (
    <Box marginBottom="40px">
      {(forschungsbeteiligte !== undefined || backButton) && (
        <Link to={CURRENT_LANG === "en" ? "/research" : "/wissenschaft"}>
          <Text className="backLink">
            {CURRENT_LANG === "en" ? "Back" : "Zurück"}
          </Text>
        </Link>
      )}
      <Heading as="h1" size="md" color="modeGray.500" mb="24px">
        {schwerpunkt}
      </Heading>
      <Heading
        as="h2"
        fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
        mb={{ base: "16px", lg: "64px" }}
        maxWidth="640px"
        lineHeight={{ base: "1.4", md: "1.2" }}
      >
        {title}
      </Heading>

      <Flex direction={["column", "column", "column", "row"]} mb="24px">
        {summary ? (
          <Box width={["100%", "100%", "100%", "50%"]} maxWidth="640px">
            <Heading as="h1" size="md" color="modeGray.500" mb="24px">
              {projektName || summaryName}
            </Heading>
            <Text
              fontWeight="semibold"
              fontSize="lg"
              className="WYSIWYG"
              mb={{ base: "40px", lg: "64px" }}
            >
              {summary}
            </Text>
          </Box>
        ) : (
          ""
        )}
        <Spacer />
        <Flex
          direction="column"
          width={["100%", "100%", "100%", "40%"]}
          maxWidth="640px"
        >
          {projektbeteiligte ? (
            <Box>
              <Heading
                display="flex"
                alignItems="center"
                as="h1"
                size="md"
                color="modeGray.500"
                mb="24px"
              >
                {projektbeteiligteName || "Projektbeteiligte"}
                <Tag
                  size={"lg"}
                  marginLeft="16px"
                  variant="solid"
                  colorScheme="modeGray"
                  border="1px solid rgba(0,0,0,.2)"
                  color="black"
                  bgColor="white"
                >
                  {CURRENT_LOCALE === "de_DE"
                    ? projektReferenz?.name
                    : projektReferenz?.tax_translation?.enTranslation}
                </Tag>
              </Heading>
              <Text pb="32px">{projektbeteiligte}</Text>
            </Box>
          ) : (
            ""
          )}

          {forschungsbeteiligte ? (
            <Box>
              <Heading
                display="flex"
                alignItems="center"
                as="h1"
                size="md"
                color="modeGray.500"
                mb="24px"
              >
                {projektbeteiligteName || "Projektbeteiligte"}
              </Heading>

              <Box>
                <Text>
                  <b>{settings.forschungsbegriffe.name}: </b>
                  {forschungsbeteiligte.textAutoren.length > 0
                    ? forschungsbeteiligte.textAutoren.map((i, k) => {
                        if (k !== forschungsbeteiligte.textAutoren.length - 1) {
                          return `${i.name}, `
                        } else {
                          return `${i.name}`
                        }
                      })
                    : ""}
                </Text>
              </Box>

              {forschungsbeteiligte?.disziplin ? (
                <Box>
                  <Text>
                    <b>{settings.forschungsbegriffe.disziplin}: </b>
                    {forschungsbeteiligte?.disziplin?.map(
                      (disziplin, index) => {
                        return CURRENT_LOCALE === "de_DE"
                          ? `${disziplin.name}${
                              index ===
                              forschungsbeteiligte?.disziplin?.length - 1
                                ? ""
                                : ", "
                            }` || ""
                          : `${disziplin?.tax_translation.enTranslation}${
                              index ===
                              forschungsbeteiligte?.disziplin?.length - 1
                                ? ""
                                : ", "
                            }` || ""
                      }
                    )}
                  </Text>
                </Box>
              ) : (
                ""
              )}

              <Box>
                <Text>
                  <b>{settings.forschungsbegriffe.institution}: </b>
                  {forschungsbeteiligte?.textInstitution || ""}
                </Text>
              </Box>
            </Box>
          ) : (
            ""
          )}

          {leitbegriffe ? (
            <Box>
              <Heading
                display="flex"
                as="h1"
                alignItems="center"
                size="md"
                color="modeGray.500"
                mb="24px"
              >
                {leitbegriffeName || "Leitbegriffe"}{" "}
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Button
                      borderRadius="full"
                      padding="0px"
                      width="24px"
                      minWidth="24px"
                      height="24px"
                      ml="8px"
                      fontWeight="bold"
                      color="buttonTertiary.700"
                      cursor="help"
                    >
                      ?
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    padding="24px"
                    border="none"
                    borderRadius="30px"
                    className="elevation_lg"
                  >
                    <Heading color="black" size="md" mb="16px">
                      {leitbegriffeName}
                    </Heading>
                    <Text
                      color="black"
                      lineHeight="1.5em"
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      {leitbegriffeBeschreibung}
                      {leitbegriffeExtendedBeschreibung}
                    </Text>
                    <Text
                      color="black"
                      pt="16px"
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="right"
                      noOfLines={1}
                    >
                      <Link
                        alt={linkTextLeitbegriffe}
                        href={linkUrlLeitbegriffe}
                        className="hover:underline"
                      >
                        {linkTextLeitbegriffe}
                      </Link>
                    </Text>
                  </PopoverContent>
                </Popover>
              </Heading>
              {leitbegriffe?.map((leitbegriff, index) => (
                <Popover trigger="hover" key={leitbegriff.id}>
                  <PopoverTrigger>
                    <Tag
                      key={index}
                      size={"lg"}
                      marginRight="8px"
                      variant="solid"
                      cursor="help"
                      rounded="md"
                      border="1px solid rgba(0,0,0,.2)"
                      color="black"
                      bgColor="white"
                      mb="1"
                    >
                      <Flex>
                        {CURRENT_LOCALE === "de_DE"
                          ? leitbegriff.name
                          : leitbegriff.keyword_translation?.enTranslation
                              ?.name}
                        <Box
                          ml="1"
                          bg="buttonTertiary.500"
                          rounded="full"
                          w="20px"
                          h="20px"
                          fontWeight="bold"
                          color="buttonTertiary.700"
                          textAlign="center"
                        >
                          ?
                        </Box>
                      </Flex>
                    </Tag>
                  </PopoverTrigger>
                  <PopoverContent
                    padding="24px"
                    border="none"
                    borderRadius="30px"
                    className="elevation_lg"
                  >
                    <Heading color="black" size="md" mb="16px">
                      {CURRENT_LOCALE === "de_DE"
                        ? leitbegriff.name
                        : leitbegriff.keyword_translation?.enTranslation?.name}
                    </Heading>
                    <Text color="black" fontWeight="normal" fontSize="sm">
                      {CURRENT_LOCALE === "de_DE"
                        ? leitbegriff.description
                        : leitbegriff.keyword_translation?.enTranslation
                            ?.beschreibung}
                    </Text>
                  </PopoverContent>
                </Popover>
              ))}
            </Box>
          ) : (
            ""
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default ProjektzielHeader
